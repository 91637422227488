import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import Field from '../field'
import useDoNotSellFields from './useDoNotSellFields'

/**
 * DoNotSellFields component renders a set of form fields for the "Do Not Sell" request form.
 * It includes fields for first name, last name, email, and state code.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object returned from `useForm`.
 * @param {string} [props.prefix=''] - An optional prefix for field names.
 * @param {boolean} [props.isFirstInputFocus=false] - Determines if the first input should be focused on mount.
 *
 * @returns {JSX.Element} The rendered component containing the form fields.
 */
const DoNotSellFields = ({form, prefix = '', isFirstInputFocus = false}) => {
    const fields = useDoNotSellFields({form, prefix})
    const nameRef = useRef()

    useEffect(() => {
        if (isFirstInputFocus && nameRef.current) {
            nameRef.current.focus()
        }
    }, [])

    return (
        <>
            <Stack spacing={24} direction={{base: 'column', lg: 'row'}}>
                <Field {...fields.firstName} inputRef={nameRef} />
                <Field {...fields.lastName} />
            </Stack>
            <Stack spacing={24} direction={{base: 'column', lg: 'row'}}>
                <Field {...fields.email} />
                <Field {...fields.stateCode} />
            </Stack>
        </>
    )
}

DoNotSellFields.propTypes = {
    /** Object returned from `useForm` */
    form: PropTypes.object.isRequired,

    /** Optional prefix for field names */
    prefix: PropTypes.string,

    /** Determines if the first input should be focused */
    isFirstInputFocus: PropTypes.bool
}

export default DoNotSellFields
