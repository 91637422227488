import {useIntl} from 'react-intl'
import {REGEX} from '../../constants'
import {stateOptions} from '@salesforce/retail-react-app/app/components/forms/state-province-options'

/**
 * Custom hook that generates form field configurations for a "Do Not Sell" request form.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.form - The form object containing control and formState.
 * @param {Object} params.form.control - The control object used for form management.
 * @param {Object} params.form.formState - The form state object containing errors.
 * @param {Object} params.form.formState.errors - The errors object for form validation.
 * @param {string} [params.prefix=''] - An optional prefix to prepend to field names.
 *
 * @returns {Object} An object containing configuration for each form field.
 * @returns {Object} return.firstName - Configuration for the first name field.
 * @returns {Object} return.lastName - Configuration for the last name field.
 * @returns {Object} return.email - Configuration for the email field.
 * @returns {Object} return.stateCode - Configuration for the state code field.
 */
export default function useDoNotSellFields({
    form: {
        control,
        formState: {errors}
    },
    prefix = ''
}) {
    const {formatMessage} = useIntl()
    const fields = {
        firstName: {
            name: `${prefix}firstName`,
            label: formatMessage({
                defaultMessage: 'First Name',
                id: 'use_do_not_sell_fields.label.first_name'
            }),
            type: 'text',
            autoComplete: 'given-name',
            defaultValue: '',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your first name.',
                    id: 'use_do_not_sell_fields.error.required_first_name'
                })
            },
            error: errors[`${prefix}firstName`],
            control
        },
        lastName: {
            name: `${prefix}lastName`,
            label: formatMessage({
                defaultMessage: 'Last Name',
                id: 'use_do_not_sell_fields.label.last_name'
            }),
            type: 'text',
            defaultValue: '',
            autoComplete: 'family-name',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your last name.',
                    id: 'use_do_not_sell_fields.error.required_last_name'
                })
            },
            error: errors[`${prefix}lastName`],
            control
        },
        email: {
            name: `${prefix}email`,
            label: formatMessage({
                defaultMessage: 'Email Address',
                id: 'use_do_not_sell_fields.label.email'
            }),
            placeholder: 'you@email.com',
            type: 'email',
            defaultValue: '',
            autoComplete: 'email',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your email address.',
                    id: 'use_do_not_sell_fields.error.required_email'
                }),
                pattern: {
                    value: REGEX.EMAIL,
                    message: formatMessage({
                        defaultMessage: 'Please enter a valid email address.',
                        id: 'use_login_fields.error.invalid_email'
                    })
                }
            },
            error: errors[`${prefix}email`],
            control
        },
        stateCode: {
            name: `${prefix}stateCode`,
            label: formatMessage({
                defaultMessage: 'State',
                id: 'use_do_not_sell_fields.label.state'
            }),
            defaultValue: '',
            type: 'select',
            options: [{value: '', label: ''}, ...stateOptions],
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please select your state.',
                    id: 'use_address_fields.error.please_select_your_state_or_province',
                    description: 'Error message for a blank state (US-specific checkout)'
                })
            },
            error: errors[`${prefix}stateCode`],
            control
        }
    }
    return fields
}
