import React from 'react'

import {Box, Button, Text} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useForm} from 'react-hook-form'
import DoNotSellFields from '../../../components/forms/do-not-sell-fields'
import FieldsetForm from '../../../components/shared/ui/FieldsetForm'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from '@chakra-ui/react'
import {Link as RouteLink} from 'react-router-dom'
import {useGDPRPolicy} from './../../../hooks/use-gdpr-policy'
import {useToast} from '../../../hooks/use-toast'

/**
 * PDCDoNotSellForm component renders a form that allows users to opt out of the sale or sharing
 * of their personal information, in compliance with certain state laws.
 *
 * This form includes fields for user input and provides information about privacy rights.
 * It uses react-hook-form for form management and validation.
 *
 * @component
 * @returns {JSX.Element} The rendered Do Not Sell form component.
 */

export const PDCDoNotSellForm = () => {
    const {formatMessage} = useIntl()
    const {sendDoNotSellUSEmail} = useGDPRPolicy()
    const showToast = useToast()

    /**
     * Handles the submission of the "Do Not Sell" form by preparing the request object
     * and sending the email with the provided user data.
     *
     * @async
     * @function handleDoNotSellSubmit
     * @param {Object} data - The user data submitted from the form.
     * @param {string} [data.email] - The email address of the user.
     * @param {string} [data.firstName] - The first name of the user.
     * @param {string} [data.lastName] - The last name of the user.
     * @param {string} [data.stateCode] - The state code of the user.
     * @returns {Promise<void>} - A promise that resolves when the email has been sent.
     *
     * @description
     * This function constructs a request object using the provided user data, which includes
     * the email, first name, last name, and state code. It then retrieves an access token
     * asynchronously and sends the "Do Not Sell" email using the constructed request object
     * and the access token. If no data is provided, the function exits early without performing
     * any operations.
     */
    const handleDoNotSellSubmit = async (data) => {
        if (!data) {
            return
        }

        var requestObj = {
            emailType: 'doNotSellUS',
            emailID: data.email || '',
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            stateCode: data.stateCode || ''
        }

        await sendDoNotSellUSEmail(requestObj)

        showToast({
            title: formatMessage({
                defaultMessage: 'Your request has been submitted.',
                id: 'do_not_sell.submit.success'
            }),
            status: 'success'
        })
    }

    const form = useForm()

    return (
        <Box
            mt={{base: '44px', lg: '34px'}}
            px={{base: '10px', lg: '40px'}}
            maxW={{base: 'unset', lg: '920px'}}
        >
            <FieldsetForm
                id="do-not-sell-form"
                data-testid="sf-auth-modal-form"
                onSubmit={form.handleSubmit(handleDoNotSellSubmit)}
                error={form.formState.errors?.global?.message}
                legendText={formatMessage({
                    defaultMessage: 'Do not sell form',
                    id: 'do_not_sell.form.legend'
                })}
                isMandatoryNote={false}
            >
                <Text variant={'bodyBase2'} maxW={{base: 'unset', lg: '756px'}}>
                    <FormattedMessage
                        defaultMessage="If you are a resident of certain states, you have the right to opt out of the sale or sharing of your personal information. Please note that you will not be opted out from transactions that the state’s law does not deem to be sales. Requests may be made only by or on behalf of a current resident of Californi, Virginia, Connecticut (effective July 1, 2023), Colorado (effective July 1, 2023), or Utah (effective December 31, 2023). To exercise this right, please use this form to submit your request or email "
                        id="do_not_sell.privacy"
                    />{' '}
                    <Link
                        href="mailto:privacy@vilebrequin.com"
                        textDecoration={'underline'}
                        fontWeight={'400'}
                    >
                        <FormattedMessage
                            defaultMessage="privacy@vilebrequin.com"
                            id="do_not_sell.privacy_mail"
                        />
                    </Link>
                    {'.'}
                </Text>
                <DoNotSellFields form={form} submitForm={handleDoNotSellSubmit} />
                <Text variant={'bodyBase2'} maxW={{base: 'unset', lg: '756px'}}>
                    <FormattedMessage
                        defaultMessage="We value your privacy and are committed to protecting your personal information. Visit our"
                        id="do_not_sell.privacy_text_1"
                    />{' '}
                    <Link
                        as={RouteLink}
                        to="/page/privacypolicy"
                        textDecoration={'underline'}
                        fontWeight={'400'}
                    >
                        <FormattedMessage
                            defaultMessage="Privacy Policy"
                            id="do_not_sell.privacy_policy"
                        />
                    </Link>{' '}
                    <FormattedMessage
                        defaultMessage="to learn more.
By submitting this request, I declare, under penalty of perjury, that (1) I am either the individual whose personal information is the subject of the request submitted above or an authorized agent who will provide proof of my ability to act on another’s behalf and (2) the information I have provided is true and accurate. I understand and acknowledge that my information will be handled by Vilebrequin in accordance with its"
                        id="do_not_sell.accessibility"
                    />{' '}
                    <Link
                        as={RouteLink}
                        to="/page/privacypolicy"
                        textDecoration={'underline'}
                        fontWeight={'400'}
                    >
                        <FormattedMessage
                            defaultMessage="Privacy Policy"
                            id="do_not_sell.privacy_policy"
                        />
                    </Link>
                    {'.'}
                </Text>
                <Button
                    type="submit"
                    onClick={() => {
                        form.clearErrors('global')
                    }}
                    isLoading={form.formState.isSubmitting}
                    variant="primary"
                >
                    <FormattedMessage defaultMessage="Submit" id="do_not_sell.submit" />
                </Button>
            </FieldsetForm>
        </Box>
    )
}
