import {useIntl} from 'react-intl'
import {REGEX} from '../../constants'

/**
 * Custom hook to generate form fields for a newsletter subscription form.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.form - The form object containing control and formState.
 * @param {Object} params.form.control - The control object used for form management.
 * @param {Object} params.form.formState - The form state object containing errors.
 * @param {Object} params.form.formState.errors - The errors object for form validation.
 * @param {string} [params.prefix=''] - An optional prefix for field names.
 * @returns {Object} - Returns an object containing configuration for email and consent fields.
 */

export default function useNewsletterFields({
    form: {
        control,
        formState: {errors}
    },
    prefix = ''
}) {
    const {formatMessage} = useIntl()
    const fields = {
        email: {
            name: `${prefix}email`,
            label: formatMessage({
                defaultMessage: 'Email Address',
                id: 'use_newsletter_fields.label.email'
            }),
            placeholder: 'you@email.com',
            defaultValue: '',
            type: 'email',
            autoComplete: 'email',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your email address.',
                    id: 'use_newsletter_fields.error.required_email'
                }),
                pattern: {
                    value: REGEX.EMAIL,
                    message: formatMessage({
                        defaultMessage: 'Please enter a valid email address.',
                        id: 'use_newsletter_fields.error.invalid_email'
                    })
                }
            },
            error: errors?.[`${prefix}email`],
            control
        },
        consent: {
            name: `${prefix}consent`,
            label: formatMessage({
                defaultMessage: `I accept to receive the latest news and promotional offers through the communication means which I have provided, in accordance with Vilebrequin's privacy policy. I can unsubscribe at any time via my online account or by clicking the unsubscribe link that appears in all Vilebrequin communications.`,
                id: 'newsletter.form.checkbox.consent'
            }),
            defaultValue: false,
            type: 'checkbox',
            autoComplete: 'honorific-prefix',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please consent to continue.',
                    id: 'use_newsletter_fields.error.required_consent'
                })
            },
            error: errors?.[`${prefix}consent`],
            control
        }
    }
    return fields
}
