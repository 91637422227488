import React from 'react'
import PropTypes from 'prop-types'

import {useIntl} from 'react-intl'
import {useStyleConfig, Button} from '@salesforce/retail-react-app/app/components/shared/ui'

/**
 * WeChatButton component renders a button styled for WeChat interactions.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The text label for the button, also used for the aria-label.
 * @param {string} props.fontColor - The color of the button text.
 * @param {string} props.fontSize - The size of the button text.
 * @param {Function} props.onWeChatOpen - The function to call when the button is clicked.
 * @param {boolean} props.isWeChatOpened - The state of the modal
 * @returns {JSX.Element} The rendered WeChat button component.
 */
const WeChatButton = ({label, fontColor, fontSize, onWeChatOpen, isWeChatOpened}) => {
    const styles = useStyleConfig('Footer')
    const {formatMessage} = useIntl()

    return (
        <Button
            onClick={onWeChatOpen}
            {...styles.link}
            color={fontColor}
            fontSize={fontSize}
            display={'inline-block'}
            height={'fit-content'}
            padding={0}
            fontWeight={500}
            aria-label={formatMessage({
                defaultMessage: 'Open WeChat modal',
                id: 'wechat.button.aria_label'
            })}
            role="button"
            tabIndex={0}
            aria-controls={'chakra-modal-wechat-modal'}
            aria-expanded={isWeChatOpened}
        >
            {label}
        </Button>
    )
}

export default WeChatButton

WeChatButton.propTypes = {
    label: PropTypes.string.isRequired,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    onWeChatOpen: PropTypes.func.isRequired,
    isWeChatOpened: PropTypes.bool
}
