import React from 'react'
import {jsx} from '@emotion/react'
import {useIntl} from 'react-intl'

import {Box, Button, Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import CookieRadioGroup from '../../../components/cookie-radio-group'
import {
    cookieSettings,
    getLocalStorageJSONItem,
    setLocalStorageJSONItem
} from './../../../utils/utils'
import {useToast} from '../../../hooks/use-toast'
import {isServer} from '@salesforce/retail-react-app/app/utils/utils'

/**
 * PDCCookiesRadios is a component that renders a form with radio buttons
 * for selecting cookie preferences. It includes a submit button to handle
 * form submission.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */

export const PDCCookiesRadios = () => {
    const showToast = useToast()
    const {formatMessage} = useIntl()

    const setLocalStorage = (cookie, value) => {
        setLocalStorageJSONItem(cookie, value)
    }

    /**
     * Handles the submission of cookie preferences form.
     *
     * This function is triggered when the cookie preferences form is submitted.
     * It prevents the default form submission behavior and processes the user's
     * cookie preferences for analytical, advertising, and social cookies.
     *
     * If the code is not running on the server, it retrieves the values of the
     * cookie preferences from the form elements, updates the cookie settings
     * accordingly, and stores these preferences in the local storage.
     *
     * After successfully updating the preferences, it displays a success toast
     * notification to inform the user that their preferences have been saved.
     *
     * @param {Event} e - The event object representing the form submission event.
     */
    const onSubmitCookies = (e) => {
        e.preventDefault()

        if (!isServer) {
            let analyticalCookie = e.currentTarget.elements.analyticalCookie.value
            let advertisingCookie = e.currentTarget.elements.advertisingCookie.value
            let socialCookie = e.currentTarget.elements.socialCookie.value

            cookieSettings(analyticalCookie, 'ga')
            cookieSettings(advertisingCookie, 'ad')
            cookieSettings(socialCookie, 'sn')

            setLocalStorage('cookie_ga', analyticalCookie)
            setLocalStorage('cookie_ad', advertisingCookie)
            setLocalStorage('cookie_sn', socialCookie)
        }

        showToast({
            title: formatMessage({
                defaultMessage: 'Preferences saved',
                id: 'cookies.preference_saved'
            }),
            status: 'success'
        })
    }

    return (
        <Box p={{base: '24px 10px 20px', lg: '32px 40px 20px'}} backgroundColor={'whiteSmoke'}>
            <form onSubmit={onSubmitCookies}>
                <Stack direction={{base: 'column', lg: 'row'}} gap={'16px'}>
                    <CookieRadioGroup
                        name={'analyticalCookie'}
                        label={formatMessage({
                            id: 'cookies_policy.cookie.label.analytical',
                            defaultMessage:
                                'Do you agree on the storage and reading of analytical Cookies?'
                        })}
                        defaultValue={
                            getLocalStorageJSONItem('cookie_ga') === undefined &&
                            getLocalStorageJSONItem('cookiesAccepted') === 'true'
                                ? 'true'
                                : getLocalStorageJSONItem('cookie_ga') === 'true'
                        }
                    />
                    <CookieRadioGroup
                        name={'advertisingCookie'}
                        label={formatMessage({
                            id: 'cookies_policy.cookie.label.advertising',
                            defaultMessage:
                                'Do you agree on the storage and reading of advertising Cookies?'
                        })}
                        defaultValue={
                            getLocalStorageJSONItem('cookie_ad') === undefined &&
                            getLocalStorageJSONItem('cookiesAccepted') === 'true'
                                ? 'true'
                                : getLocalStorageJSONItem('cookie_ad') === 'true'
                        }
                    />
                    <CookieRadioGroup
                        name={'socialCookie'}
                        label={formatMessage({
                            id: 'cookies_policy.cookie.label.social',
                            defaultMessage:
                                'Do you agree on the storage and reading of Cookies allowing social media sharing?'
                        })}
                        defaultValue={
                            getLocalStorageJSONItem('cookie_sn') === undefined &&
                            getLocalStorageJSONItem('cookiesAccepted') === 'true'
                                ? 'true'
                                : getLocalStorageJSONItem('cookie_sn') === 'true'
                        }
                    />
                </Stack>
                <Button type="submit" variant="primary" mt={'16px'}>
                    {formatMessage({
                        id: 'cookies_policy.button.save',
                        defaultMessage: 'Save preferences'
                    })}
                </Button>
            </form>
        </Box>
    )
}
