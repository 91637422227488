import React, {useState} from 'react'
import {
    Text,
    useStyleConfig,
    Stack,
    Box
} from '@salesforce/retail-react-app/app/components/shared/ui'
import LocaleSelector from '../../components/locale-selector'
import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'

import {getPathWithLocale} from '@salesforce/retail-react-app/app/utils/url'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useShipToCountry from '../../hooks/use-ship-to-country'
import colors from '../../theme/foundations/colors'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {PDCFooterPaymentIcon} from '../../page-designer/assets'
import {PDCFooterPayments} from '../../page-designer/layouts'
import {usePage} from '@salesforce/commerce-sdk-react'
import {HOME_HREF} from '../../constants'

// Utils
import {getEnvStaleTime} from '../../utils/utils'

const PAGEDESIGNER_TO_COMPONENT_PAYMENTS = {
    'commerce_assets.pdcFooterPaymentIcon': PDCFooterPaymentIcon,
    'commerce_layouts.pdcFooterPayments': PDCFooterPayments
}
const FooterBottom = () => {
    const styles = useStyleConfig('Footer')
    const intl = useIntl()
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const supportedLocaleIds = l10n?.supportedLocales.map((locale) => locale.id)
    const {shipToCountry} = useShipToCountry()
    const location = useLocation()
    const isCheckout = /\/checkout$/.test(location?.pathname)
    const [contextValuePayments] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_PAYMENTS
    })
    const staleTime = getEnvStaleTime()
    const {data: footerPage, isLoading} = usePage(
        {parameters: {pageId: 'footer'}},
        {staleTime: staleTime}
    )

    const footerPaymentsComponent = isLoading
        ? null
        : footerPage?.regions?.[0].components?.find(
              (comp) => comp?.typeId === 'commerce_layouts.pdcFooterPayments'
          )

    return (
        <Box px={{base: '10px', lg: '20px'}}>
            <Stack
                direction={{base: 'column', lg: 'row-reverse'}}
                justifyContent={'space-between'}
                alignItems={{base: '', lg: 'center'}}
                borderTop={{base: '', lg: `1px solid ${colors.borderGray}`}}
                marginTop={{base: '', lg: '44px'}}
                paddingY={{base: '', lg: '12px'}}
            >
                {isCheckout && footerPaymentsComponent ? (
                    <PageContext.Provider value={contextValuePayments}>
                        <Component component={footerPaymentsComponent} />
                    </PageContext.Provider>
                ) : (
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={{base: 'center', lg: 'flex-start'}}
                    >
                        <Link {...styles.footerLink} to={HOME_HREF} textDecoration="none">
                            <Text
                                variant={'bodyBase1'}
                                paddingTop={{base: '12px', lg: '12px'}}
                                lineHeight={'24px'}
                                alignSelf={'flex-start'}
                            >
                                {`${intl.formatMessage({
                                    id: 'footer.shipto',
                                    defaultMessage: 'Ship to'
                                })}`}
                                {': '}
                                <Text
                                    as={'span'}
                                    textTransform={'uppercase'}
                                    textDecoration={'underline'}
                                >
                                    {shipToCountry.id}
                                </Text>
                            </Text>
                        </Link>
                        <LocaleSelector
                            {...styles.localeSelector}
                            selectedLocale={intl.locale}
                            locales={supportedLocaleIds}
                            onSelect={(newLocale) => {
                                // Update the `locale` in the URL.
                                const newUrl = getPathWithLocale(newLocale, buildUrl, {
                                    disallowParams: ['refine']
                                })
                                window.location = newUrl
                            }}
                        />
                    </Stack>
                )}
                <Text
                    variant={'bodyBase1'}
                    textTransform={'uppercase'}
                    alignSelf={'flex-start'}
                    lineHeight={'24px'}
                    paddingTop={{base: '', lg: '12px'}}
                    paddingBottom={{base: isCheckout ? '40px' : '24px', lg: '12px'}}
                >
                    {intl.formatMessage({
                        id: 'footer.brand',
                        defaultMessage: 'vilebrequin©2024'
                    })}
                </Text>
            </Stack>
        </Box>
    )
}

export default FooterBottom

FooterBottom.propTypes = {}
