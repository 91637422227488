const space = {
    0: '0px',
    1: '1px',
    2: '2px',
    3: '3px',
    4: '4px',
    5: '5px',
    6: '6px',
    7: '7px',
    8: '8px',
    9: '9px',
    10: '10px',
    12: '12px',
    14: '14px',
    15: '15px',
    16: '16px',
    20: '20px',
    24: '24px',
    25: '25px',
    30: '30px',
    32: '32px',
    35: '35px',
    40: '40px',
    45: '45px',
    48: '48px',
    50: '50px',
    56: '56px',
    60: '60px',
    70: '70px',
    80: '80px',
    90: '90px',
    100: '100px'
}

export default space
