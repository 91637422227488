'use strict'

import {createScapiFetch} from './utils'

class NewsletterMgr {

    constructor(config) {
        this.config = config
        this.fetch = createScapiFetch(config)
    }

    /**
     * Updates consent attributes for a user by making an asynchronous API call.
     *
     * @param {string} email - The email address of the user whose consent attributes are to be updated.
     * @param {string} shipToCountry - The country to which the user ships products.
     * @param {string} accessToken - The access token used for authentication in the API request.
     * @param {string} siteId - The identifier for the site where the consent attributes are being updated.
     * @param {string} locale - The locale identifier for the request, affecting language and regional settings.
     * @returns {Promise<Object>} A promise that resolves to the result of the consent attributes update operation, or an empty object if the operation fails.
     */
    async updateConsentAttributes(email, shipToCountry, accessToken, siteId, locale) {
        let urlParam = {
            querystring: {}
        }

        const body = {
            email: email,
            shipToCountry: shipToCountry
        }

        let endpoint = 'update-consent-attributes'
        let method = 'POST'
        let args = [urlParam]
        let methodName = 'updateConsentAttributes'
        let requestParams = {
            body: body,
            apiPath: 'newsletter',
            accessToken: accessToken,
            isCached: false,
            siteID: siteId,
            localeID: locale
        }
        let apiVersion = 'v1'

        let updateConsentAttributesResult = await this.fetch(
            endpoint,
            method,
            args,
            methodName,
            requestParams,
            apiVersion
        )

        return updateConsentAttributesResult || {}
    }
}

export default NewsletterMgr
