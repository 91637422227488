import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import {jsx} from '@emotion/react'
import {FormLabel, Radio, RadioGroup, Stack} from '@chakra-ui/react'
import {useIntl} from 'react-intl'

/**
 * A component that renders a radio group for selecting cookie preferences.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.label - The label for the radio group.
 * @param {string} props.name - The name attribute for the radio group.
 * @param {boolean} props.defaultValue - The name attribute for the radio group.
 *
 * @returns {JSX.Element} The rendered radio group component.
 */
const CookieRadioGroup = ({label, name, defaultValue}) => {
    const intl = useIntl()
    const [value, setValue] = useState(null)

    const handleChange = (nextValue) => {
        setValue(nextValue === 'true')
    }
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    return (
        <RadioGroup
            value={value?.toString()}
            onChange={handleChange}
            name={name}
            bgColor={'white'}
            p={'16px'}
            flex={'0 1 33%'}
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'column'}
        >
            <FormLabel mb={'16px'} color={'black'}>
                {label}
            </FormLabel>
            <Stack direction="row">
                <Radio value={'true'} mr={'40px'}>
                    {intl.formatMessage({
                        id: 'cookies_policy.cookie.radio.yes',
                        defaultMessage: 'Yes'
                    })}
                </Radio>
                <Radio value={'false'}>
                    {intl.formatMessage({
                        id: 'cookies_policy.cookie.radio.no',
                        defaultMessage: 'No'
                    })}
                </Radio>
            </Stack>
        </RadioGroup>
    )
}

CookieRadioGroup.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.bool
}

export default CookieRadioGroup
