export default {
    baseStyle: () => ({
        container: {
            position: 'relative'
        },
        link: {
            display: 'block',
            textDecoration: 'none'
        },
        favIcon: {
            position: 'absolute',
            top: 0,
            right: 0,
            minWidth: 'auto',
            width: '30px',
            height: '30px'
        },
        imageWrapper: {
            position: 'relative',
            _hover: {
                '& .main-image': {
                    display: 'none'
                },
                '& .hover-image': {
                    display: 'block'
                }
            }
        },
        image: {
            ratio: 1,
            bg: 'whiteSmoke'
        },
        hoverImage: {
            display: 'none'
        },
        price: {
            display: 'flex',
            gap: '6px'
        },
        title: {
            paddingTop: '10px',
            px: '10px'
        },
        rating: {},
        variations: {},
        badgeGroup: {
            position: 'absolute',
            top: '6px',
            left: '10px',
            gap: '5px'
        },
        badgeLink: {
            textDecoration: 'none',
            _hover: {
                textDecoration: 'none'
            }
        },
        promoCallout: {
            display: 'inline-block',
            color: 'white',
            backgroundColor: 'fullBlack',
            px: 2
        },
        colorList: {
            display: 'flex',
            gap: '6px',
            padding: '10px'
        },
        colorLink: {
            display: 'block'
        },
        colorChip: {
            width: '6px',
            height: '6px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: 'full',
            border: '1px solid',
            borderColor: 'fullBlack',
            boxSizing: 'content-box'
        }
    }),
    parts: [
        'container',
        'imageWrapper',
        'image',
        'price',
        'title',
        'rating',
        'variations',
        'badgeGroup'
    ]
}
