import {COUNTRIES_CURRENCY, DISPATCH_COUNTRIES} from '../constants'

/**
 * Retrieves the shipping country configuration based on the provided country code.
 * If no country code is provided, it defaults to the country derived from the locale.
 *
 * @param {string} countryCode - The ISO code of the country to retrieve.
 * @param {Object} locale - The locale object containing locale-specific information.
 * @param {string} locale.id - The locale identifier in the format 'language-country'.
 * @param {string} locale.preferredCurrency - The preferred currency for the locale.
 *
 * @returns {Object} An object representing the country configuration.
 * @returns {string} return.id - The ISO code of the country.
 * @returns {string} return.currency - The currency associated with the country.
 */
export const getShipToCountry = (countryCode, locale) => {
    // if no countries are configured, return default country
    const countries = COUNTRIES_CURRENCY
    if (!countryCode) {
        const countryID = locale?.id.split('-')[1]
        const defaultCountry = countries.filter((country) => country.id === countryID)[0]
        return {
            id: defaultCountry.id,
            currency: defaultCountry.currency
        }
    }
    const country = countries.filter((country) => country.id === countryCode)[0]
    // get country config
    return country
}

/**
 * Sorts the shipping countries by name.
 * @returns {code: string, name: string}[] - an array of sorted countries by name
 */
export const sortShipToCountries = () => {
    return DISPATCH_COUNTRIES.flatMap((country) => country.countries).sort((a, b) =>
        a.name.localeCompare(b.name)
    )
}

/**
 * Resolves the country code from a given URL path.
 *
 * This function extracts the 'country' parameter from the query string of a given URL path.
 * It constructs a valid URL using a dummy domain to facilitate the extraction process.
 *
 * @param {string} path - The partial URL path containing query parameters.
 * @returns {string|null} - The country code extracted from the URL, or null if the 'country' parameter is not present.
 */
export const resolveCountryFromUrl = (path) => {
    // since url is a partial url, we pass in a dummy domain to create a validate url to pass into URL constructor
    const {search} = new URL(path, 'https://www.some-domain.com')
    const urlParams = new URLSearchParams(search)
    const country = urlParams.get('country')
    // can be country or null if it is not defined in url
    return country
}
