import React from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import Link from '@salesforce/retail-react-app/app/components/link'
import colors from '../../../theme/foundations/colors'
import {
    List,
    ListItem,
    Text,
    useBreakpointValue,
    VisuallyHidden
} from '@salesforce/retail-react-app/app/components/shared/ui'

/**
 * Suggestion component that displays a list of suggestion links with a title.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the suggestion list.
 * @param {Array<Object>} props.suggestion - An array of suggestion objects.
 * @param {string} props.suggestion[].url - The URL for the suggestion link.
 * @param {string} props.suggestion[].label - The label for the suggestion link.
 * @param {Function} props.updateLinkRefs - A function to update the reference to each link element.
 *
 * @returns {JSX.Element} The rendered component.
 */

const Suggestion = ({title, suggestion, redirect, updateLinkRefs}) => {
    const isDesktop = useBreakpointValue({base: false, lg: true})
    const intl = useIntl()

    const titleStyle = {
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: 500,
        mb: '12px'
    }

    const maxLinks = isDesktop ? 10 : 8
    const links = suggestion.slice(0, maxLinks)
    return (
        <List flex={{base: 'unset', lg: '0 0 50%'}} mb={'12px'}>
            <Text {...titleStyle}>{title}</Text>
            {links.map((link, index) => (
                <ListItem key={index}>
                    <Link
                        ref={(el) => updateLinkRefs(el)}
                        color={colors.darkGray}
                        fontSize={'11px'}
                        mb={'12px'}
                        textDecoration={'none'}
                        display={'block'}
                        to={link.link}
                        onClick={() => {
                            redirect()
                        }}
                    >
                        {link.name}
                    </Link>
                </ListItem>
            ))}
            <VisuallyHidden aria-live="assertive">
                {`${links.length} ${intl.formatMessage({
                    defaultMessage: 'suggestions available',
                    id: 'search.suggestions.count'
                })}`}
            </VisuallyHidden>
        </List>
    )
}

Suggestion.propTypes = {
    title: PropTypes.string.isRequired,
    suggestion: PropTypes.array.isRequired,
    redirect: PropTypes.func,
    updateLinkRefs: PropTypes.func
}

export default Suggestion
