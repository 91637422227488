'use strict'

import NewsletterMgr from '../custom-api/newsletter-custom-api'
import {useAccessToken} from '@salesforce/commerce-sdk-react'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useShipToCountry from './use-ship-to-country'

/**
 * Provides functionalities to interact with user profiles, including searching for
 * an email in the newsletter database and updating custom attributes for a user profile.
 *
 * This hook utilizes the current site and locale context and requires an access token
 * for making authenticated API requests. It offers two main asynchronous functions:
 * `searchEmail` for checking the existence of an email in the newsletter profiles,
 * and `updateCustomAttributes` for modifying user profile attributes.
 *
 * @returns {Object} An object containing the following methods:
 *
 * @property {Function} searchEmail - Asynchronously searches for a profile email in the newsletter database.
 * @property {Function} updateCustomAttributes - Updates custom attributes for a user profile.
 */
export const useProfile = () => {
    const {shipToCountry} = useShipToCountry()
    const {getTokenWhenReady} = useAccessToken()
    const {app: appConfig} = getConfig()
    const {site, locale} = useMultiSite()
    const api = new NewsletterMgr({
        parameters: {
            siteId: site.id,
            organizationId: appConfig.commerceAPI.parameters.organizationId,
            locale: locale.id
        }
    })

    return {
        /**
         * Updates the profile consent attributes for a given email by searching for the email profile.
         *
         * @async
         * @param {string} email - The email address to search for in the profile database.
         * @returns {Promise<boolean>} A promise that resolves to `true` if the email profile is found and consent attributes are updated successfully, otherwise `false`.
         *
         * @throws Will return `false` if there is an error during the process, such as failure to retrieve the access token or search the profile email.
         */
        async updateProfileConsentAttributes(email) {
            try {
                const accessToken = await getTokenWhenReady()
                const newsletterEmailResult = await api.updateConsentAttributes(
                    email,
                    shipToCountry.id,
                    accessToken,
                    site.id,
                    locale.id
                )

                if (newsletterEmailResult.success) {
                    return true
                }

                return false
            } catch (error) {
                return false
            }
        }
    }
}
