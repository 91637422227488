'use strict'

import {createScapiFetch} from './utils'

class EmailMgr {
    constructor(config) {
        this.config = config
        this.fetch = createScapiFetch(config)
    }

    /**
     * Sends an email using the specified parameters and access token.
     *
     * This function constructs the necessary request body and parameters to send an email
     * through an API endpoint. It utilizes an asynchronous fetch method to perform the
     * HTTP POST request and handles any errors that may occur during the process.
     *
     * @param {Object} parameters - An object containing the email parameters.
     * @param {string} parameters.emailID - The ID of the email to be sent.
     * @param {string} parameters.emailType - The type of the email.
     * @param {string} parameters.token - A token associated with the email.
     * @param {Object} parameters.context - Additional context for the email.
     * @param {string} parameters.firstName - The first name of the recipient.
     * @param {string} parameters.lastName - The last name of the recipient.
     * @param {string} parameters.stateCode - The state code relevant to the email.
     * @param {string} accessToken - The access token for authentication.
     * @returns {Promise<Object>} A promise that resolves to the response of the email sending operation.
     * @throws {Error} Throws an error if the email sending process fails.
     */
    async sendEmail(parameters, accessToken) {
        let params = {
            querystring: {}
        }

        let body = {
            emailID: parameters.emailID || '',
            emailType: parameters.emailType || '',
            token: parameters.token || '',
            context: parameters.context || {},
            firstName: parameters.firstName || '',
            lastName: parameters.lastName || '',
            stateCode: parameters.stateCode || ''
        }

        let endpoint = 'send'
        let method = 'POST'
        let args = [params]
        let methodName = 'sendEmail'
        let apiVersion = 'v1'
        let requestParams = {
            body: body,
            accessToken: accessToken,
            isCached: false,
            apiPath: 'send-email'
        }

        try {
            let sendEmailResponse = await this.fetch(
                endpoint,
                method,
                args,
                methodName,
                requestParams,
                apiVersion
            )

            return sendEmailResponse || {}
        } catch (error) {
            throw new Error('Error sending email')
        }
    }
}

export default EmailMgr
