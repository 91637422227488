import React, {useState, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {
    Modal,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    Text,
    FormControl,
    FormLabel,
    useDisclosure
} from '@chakra-ui/react'
import {Button} from '@salesforce/retail-react-app/app/components/shared/ui/Button'
import {useToast} from '../../hooks/use-toast'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useShipToCountry from '../../hooks/use-ship-to-country'
import {TURKEY_PARTNER_SITE, RUSSIA_PARTNER_SITE, HOME_HREF} from '../../constants'
import {getShipToCountry, sortShipToCountries} from '../../utils/ship-to-country-utils'
import {
    useShopperContext,
    useShopperContextsMutation,
    useUsid,
    useCommerceApi,
    useAccessToken
} from '@salesforce/commerce-sdk-react'
import {ChevronDownIcon, LoaderIcon} from '../custom-icons'
import {
    getLocalStorageJSONItem,
    clearLocalStorageJSONItem,
    setLocalStorageJSONItem
} from '../../utils/utils'
import {useHistory, useLocation} from 'react-router-dom'

/**
 * CountrySelectorModal is a React functional component that provides a modal interface for users to select and change their country.
 *
 * This component utilizes various hooks and context providers to manage the state and behavior of the modal. It fetches the user's
 * IP address to determine their geolocation and suggests a country based on this information. Users can confirm the suggested country
 * or select a different one from a dropdown list. Upon confirmation, the shopper context is updated with the selected country, and
 * appropriate actions such as redirection or local storage updates are performed.
 *
 * The modal is designed to be responsive and user-friendly, providing feedback through toast notifications in case of errors or
 * successful operations. It also handles specific redirection cases for certain countries.
 *
 * @component
 * @returns {JSX.Element} A modal component that allows users to change their country.
 *
 * @example
 * <CountrySelectorModal />
 *
 * @description
 * - The component uses the `useDisclosure` hook to manage the modal's open and close states.
 * - It fetches the user's IP address and geolocation using an asynchronous function `getIPAndOpenModal`.
 * - The `handleCountryChange` function updates the selected country code based on user input.
 * - The `handleConfirm` function updates the shopper context with the selected country and handles redirection if necessary.
 * - The `handleClose` function updates the shopper context with the geolocation country and closes the modal.
 * - The `setShopperContextCountry` function sets the shopper context country and currency, either updating an existing context or creating a new one.
 * - The component sorts and displays a list of countries for selection.
 * - It uses the `useEffect` hook to determine whether geolocation should be checked and performs necessary actions based on the country code.
 */
const CountrySelectorModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [geolocCountry, setGeolocCountry] = useState()
    const [geolocCountryCode, setGeolocCountryCode] = useState()
    const [selectedCountryCode, setSelectedCountryCode] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const {site, locale, buildUrl} = useMultiSite()
    const {setShipToCountry} = useShipToCountry()
    const createShopperContext = useShopperContextsMutation('createShopperContext')
    const updateShopperContext = useShopperContextsMutation('updateShopperContext')
    const history = useHistory()
    const location = useLocation()

    const {usid} = useUsid()
    const {
        data: shopperContext,
        isSuccess,
        isLoading: isShopperContextLoading
    } = useShopperContext({
        parameters: {usid: usid}
    })
    const api = useCommerceApi()
    const {getTokenWhenReady} = useAccessToken()

    const urlParams = new URLSearchParams(location.search)
    // If the URL contains a country query parameter, it will be used to set the shopper context country.
    const country = urlParams.get('country')

    /**
     * Asynchronously retrieves the user's IP address and opens a modal with country selection options based on the IP-derived location.
     *
     * This function performs the following steps:
     * 1. Fetches the user's IP address from the '/ip' endpoint.
     * 2. Uses the IP address to create a shopper context, which determines the user's geographical location.
     * 3. Parses the geolocation data from the response headers.
     * 4. Sets the country and country code based on the geolocation data.
     * 5. Opens a modal to allow the user to select their country.
     *
     * If any step fails, an error message is logged to the console, and a toast notification is displayed to inform the user of the failure.
     *
     * @async
     * @function
     * @throws Will throw an error if the IP address cannot be retrieved or if the shopper context cannot be updated.
     */
    const getIPAndOpenModal = async () => {
        try {
            // Get IP address of the user
            const response = await fetch('/ip', {
                method: 'GET'
            })
            const result = await response.json()
            const userIP = result.clientIp // example IP: '212.58.75.254'

            if (!userIP) {
                throw new Error('Unable to retrieve IP address.')
            }

            // Create a shopper context to retrieve country according to user's IP
            const token = await getTokenWhenReady()
            const contextParams = {
                parameters: {
                    usid: usid
                },
                headers: {
                    authorization: `Bearer ${token}`
                },
                body: {
                    clientIp: userIP
                }
            }
            const resp = await api.shopperContexts.createShopperContext(contextParams, true)
            const geoLocation = resp?.headers?.get('x-geolocation')

            // Set variables for modal display and open it
            if (geoLocation) {
                const geoData = geoLocation.split('; ').reduce((acc, item) => {
                    const [key, value] = item.split(': ')
                    acc[key] = value
                    return acc
                }, {})
                setGeolocCountry(geoData['Country'])
                setGeolocCountryCode(geoData['CountryCode'])
                setSelectedCountryCode(geoData['CountryCode'])
                onOpen()
            }
        } catch (error) {
            console.error('Error fetching IP or updating shopper context:', error)
            toast({
                title: 'Error fetching user location.',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        }
    }

    /**
     * Handles the change event for the country selection dropdown.
     *
     * This function is triggered when the user selects a different country from the dropdown menu.
     * It updates the state with the newly selected country's code.
     *
     * @param {Object} event - The event object associated with the change event.
     * @param {Object} event.target - The target element of the event.
     * @param {string} event.target.value - The value of the selected country code.
     */
    const handleCountryChange = (event) => {
        setSelectedCountryCode(event.target.value)
    }

    /**
     * Handles the confirmation action for the country selection modal.
     *
     * This function manages the redirection and context setting based on the selected country code.
     * It performs the following actions:
     *
     * - Sets the loading state to true at the beginning of the process.
     * - Redirects the user to the appropriate site based on the selected country code and current site alias:
     *   - If the selected country is the United States ('US') and the current site alias is 'eu', redirects to the US site.
     *   - If the selected country is not the United States and the current site alias is 'us', redirects to the EU site.
     *   - If the selected country is Turkey ('TR'), redirects to the Turkey partner site.
     *   - If the selected country is Russia ('RU'), redirects to the Russia partner site.
     * - For other countries, attempts to update the shopper context and ship-to country:
     *   - Calls `getShipToCountry` to determine the shipping country.
     *   - Updates the shopper context with `setShopperContextCountry`.
     *   - Sets the ship-to country state.
     *   - Displays a success toast notification upon successful update.
     *   - Closes the modal using `onClose`.
     * - Catches and handles errors during the update process:
     *   - Displays an error toast notification with the error message.
     * - Resets the loading state to false at the end of the process.
     *
     * @async
     * @function handleConfirm
     */
    const handleConfirm = async () => {
        setIsLoading(true)
        // Handle redirection cases
        const preferredLocale = navigator.language.split('-')[0]
        if (selectedCountryCode === 'US' && site.alias === 'eu') {
            window.location.href =
                buildUrl(HOME_HREF, 'us', preferredLocale) + `?country=${selectedCountryCode}`
        } else if (selectedCountryCode !== 'US' && site.alias === 'us') {
            window.location.href =
                buildUrl(HOME_HREF, 'eu', preferredLocale) + `?country=${selectedCountryCode}`
        } else if (selectedCountryCode === 'TR') {
            window.location.href = TURKEY_PARTNER_SITE
        } else if (selectedCountryCode === 'RU') {
            window.location.href = RUSSIA_PARTNER_SITE
        } else {
            // Handle other cases
            try {
                const shipToCountry = getShipToCountry(selectedCountryCode, null)

                await setShopperContextCountry(shipToCountry)
                setShipToCountry(shipToCountry)
                toast({
                    title: 'Country updated successfully.',
                    description: '',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                })
                onClose()
            } catch (error) {
                toast({
                    title: 'An error occurred.',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                })
            }
        }
        setIsLoading(false)
    }

    /**
     * Handles the closure of the country selector modal by performing several operations:
     * 1. Retrieves the shipping country based on the geolocation country code.
     * 2. Stores the country code in local storage.
     * 3. Updates the state with the selected shipping country.
     * 4. Sets the shopper context to the selected country.
     * 5. Invokes the onClose callback to close the modal.
     *
     * @async
     * @function handleClose
     * @returns {Promise<void>} A promise that resolves when all operations are complete.
     */
    const handleClose = async () => {
        const shipToCountry = getShipToCountry(null, locale)
        setLocalStorageJSONItem('countryCode', shipToCountry?.id)
        setShipToCountry(shipToCountry)
        await setShopperContextCountry(shipToCountry)
        onClose()
    }

    /**
     * Sets the shopper context country and currency.
     * Updates an existing shopper context if it already exists, otherwise creates a new shopper context.
     * @param {object} countryAndCurrency - The object containing the country and currency to set
     */
    const setShopperContextCountry = async ({id, currency}) => {
        const options = {
            parameters: {
                usid: usid
            },
            body: {
                geoLocation: {
                    countryCode: id
                },
                customQualifiers: {
                    currency: currency
                }
            }
        }

        if (shopperContext?.data) {
            await updateShopperContext.mutateAsync(options)
        } else {
            await createShopperContext.mutateAsync(options)
        }
    }

    // Concatenate and sort countries
    const countries = sortShipToCountries()

    useEffect(() => {
        const newCountryCode = getLocalStorageJSONItem('countryCode') || country
        /**
         * Determines whether geolocation should be checked and performs necessary actions based on the country code.
         *
         * This asynchronous function checks if a new country code is provided and updates the shopper context
         * and local storage accordingly. If no new country code is provided, it evaluates the success status
         * and loading state of the shopper context to decide whether to retrieve the IP address and open a modal.
         *
         * @async
         * @function shouldCheckGeolocation
         * @returns {Promise<void>} A promise that resolves when the necessary actions are completed.
         *
         * @description
         * - If a `newCountryCode` is available, the function retrieves the corresponding shipping country,
         *   updates the shopper context with this country, sets the shipping country, and clears the local
         *   storage item for 'countryCode'.
         * - If no `newCountryCode` is provided, the function checks the success and loading status of the
         *   shopper context. If the context is not successful and not loading, or if it is successful but
         *   lacks a geolocation country code, it triggers the retrieval of the IP address and opens a modal.
         */
        async function shouldCheckGeolocation() {
            if (newCountryCode) {
                const shipToCountry = getShipToCountry(newCountryCode, null)
                await setShopperContextCountry(shipToCountry)
                setShipToCountry(shipToCountry)
                clearLocalStorageJSONItem('countryCode')
                // Remove the country query parameter from the URL after processing it.
                urlParams.delete('country')
                history.replace({
                    pathname: location.pathname,
                    search: urlParams.toString()
                })
            } else if (
                (!isSuccess && !isShopperContextLoading) ||
                (isSuccess && !shopperContext?.geoLocation?.countryCode)
            ) {
                getIPAndOpenModal()
            }
        }

        shouldCheckGeolocation()
    }, [isShopperContextLoading])

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="small" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    <FormattedMessage
                        id="country_selector_modal.title"
                        defaultMessage="CHANGE YOUR COUNTRY"
                    />
                </ModalHeader>
                <ModalBody>
                    <Text variant="bodyLarge2" mb="1rem">
                        <FormattedMessage
                            id="country_selector_modal.description"
                            defaultMessage="You are currently visiting Vilebrequin.com from {country}. Would you like to update your location?"
                            values={{country: geolocCountry}}
                        />
                    </Text>
                    <FormControl variant="floating">
                        <Select
                            onChange={handleCountryChange}
                            id="selectCountry"
                            variant="basic"
                            defaultValue={geolocCountryCode}
                            icon={<ChevronDownIcon />}
                        >
                            {countries.map((country) => (
                                <option key={country.code} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </Select>
                        <FormLabel htmlFor="selectCountry"></FormLabel>
                    </FormControl>
                </ModalBody>
                <ModalFooter width="full" p="0">
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        isLoading={isLoading}
                        spinner={<LoaderIcon size={8} color="white" />}
                        w="full"
                    >
                        <FormattedMessage
                            id="country_selector_modal.button"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CountrySelectorModal
