import React, {useEffect, useRef, useState} from 'react'
import {
    Box,
    Text,
    useStyleConfig,
    Heading,
    createStylesContext,
    Image,
    Stack,
    Accordion,
    useBreakpointValue,
    useDisclosure,
    InputRightElement,
    IconButton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'

import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import colors from '../../theme/foundations/colors'
import {usePage} from '@salesforce/commerce-sdk-react'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {
    PDCFooterLinksListMobile,
    PDCFooterLinksListDesktop,
    PDCFooterLinksListCheckout,
    PDCFooterReinsurance
} from '../../page-designer/layouts'
import {PDCFooterReinsuranceIcon, PDCLink} from '../../page-designer/assets'
import FooterBottom from './footer-bottom'
import {useLocation} from 'react-router-dom'
import {NewsletterModal} from '../newsletter-modal'
import {useSplioService} from './../../hooks/use-splio-service'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import Field from '../field'
import {RightArrowIcon} from '../custom-icons'
import FieldsetForm from '../shared/ui/FieldsetForm'
import useNewsletterFields from '../forms/useNewsletterFields'
import {useIsLocale} from '../../hooks/use-is-locale'
import {useProfile} from './../../hooks/use-update-profile-attributes.js'

// Utils
import {getEnvStaleTime} from '../../utils/utils'
import {useToast} from '../../hooks/use-toast.js'

const PAGEDESIGNER_TO_COMPONENT_MOBILE = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListMobile
}

const PAGEDESIGNER_TO_COMPONENT_DESKTOP = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListDesktop
}

const PAGEDESIGNER_TO_COMPONENT_CHECKOUT = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListCheckout
}
const PAGEDESIGNER_TO_COMPONENT_REINSURANCE = {
    'commerce_assets.pdcFooterReinsuranceIcon': PDCFooterReinsuranceIcon,
    'commerce_layouts.pdcFooterReinsurance': PDCFooterReinsurance
}
const [StylesProvider] = createStylesContext('Footer')

/**
 * Footer Component
 *
 * This component renders the footer section of the application, including different layouts
 * for mobile, desktop, and checkout pages. It dynamically loads footer links and other
 * components based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <Footer />
 *
 * @returns {JSX.Element} The rendered footer component.
 */

const Footer = () => {
    const {updateProfileConsentAttributes} = useProfile()
    const [newsletterEmail, setNewsletterEmail] = useState(null)
    const styles = useStyleConfig('Footer')
    const showToast = useToast()
    const {formatMessage} = useIntl()
    const isDesktop = useBreakpointValue({base: false, lg: true})
    const location = useLocation()
    const {isAlreadySubscribed, isBlackListedEmail, forwardAccountToSplio} = useSplioService()
    const {site, locale} = useMultiSite()
    const isCheckout = /\/checkout$/.test(location?.pathname)

    // Get the header page from page designer to display the navigation links
    const staleTime = getEnvStaleTime()
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}}, {staleTime: staleTime})
    const footerLinksListComponents = footerPage?.regions?.[0].components?.filter(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterLinksList'
    )
    const [contextValueMobile] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_MOBILE
    })
    const [contextValueDesktop] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_DESKTOP
    })

    const [contextValueCheckout] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_CHECKOUT
    })
    const newsletterButtonRef = useRef(null)
    const [newsletterButtonWidth, setNewsletterButtonWidth] = useState(0)

    useEffect(() => {
        if (newsletterButtonRef.current) {
            setNewsletterButtonWidth(newsletterButtonRef.current.offsetWidth)
        }
    }, [])
    const checkoutFooterStyle = isDesktop
        ? {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              pt: '70px'
          }
        : null
    const form = useForm()
    const {
        formState: {isSubmitting}
    } = form
    const fields = useNewsletterFields({form, prefix: 'newsletter_footer'})

    const {
        isOpen: isNewsletterModalOpen,
        onOpen: onNewsletterModalOpen,
        onClose: onNewsletterModalClose
    } = useDisclosure()

    /**
     * Handles the closing of the newsletter modal.
     *
     * This function resets the newsletter email state to null and then
     * calls the `onNewsletterModalClose` function to close the modal.
     */
    const handleNewsletterModalClose = () => {
        setNewsletterEmail(null)
        onNewsletterModalClose()
    }

    /**
     * Handles the newsletter subscription process based on the provided data.
     *
     * This function checks if the email is present in the data object. If an email
     * is provided, it sets the newsletter email state and determines the appropriate
     * action based on the user's location (US or non-US).
     *
     * @async
     * @function handleNewsletterSubscription
     * @param {Object} data - The data object containing subscription details.
     * @param {string} data.newsletter_footeremail - The email address to be subscribed.
     *
     * @returns {void}
     *
     * @example
     * handleNewsletterSubscription({ newsletter_footeremail: 'user@example.com' });
     *
     * @description
     * - If the email is not present in the data object, the function exits early.
     * - If the user is located in the US, it triggers the Splio call with the email.
     * - If the user is not located in the US, it opens the newsletter modal.
     */
    const isUS = useIsLocale('en-US')

    const handleNewsletterSubscription = async (data) => {
        if (!data.newsletter_footeremail) {
            return
        }
        setNewsletterEmail(data.newsletter_footeremail)
    }
    /**
     * Checks if the email in the request object is already subscribed.
     *
     * This function calls the `isAlreadySubscribed` service to determine if the email
     * provided in the `requestObject` is already subscribed to the newsletter or service.
     *
     * @param {Object} requestObject - The request object containing the email and other relevant data.
     * @param {string} requestObject.email - The email address to check for subscription status.
     * @returns {Promise<Object>} A promise that resolves to the result of the subscription check.
     */
    const emailAlreadySubscribed = async (requestObject) => await isAlreadySubscribed(requestObject)

    /**
     * Checks if the email in the request object is blacklisted.
     *
     * This function calls the `isBlackListedEmail` service to determine if the email
     * provided in the `requestObject` is blacklisted.
     *
     * @param {Object} requestObject - The request object containing the email and other relevant data.
     * @param {string} requestObject.email - The email address to check against the blacklist.
     * @returns {Promise<Object>} A promise that resolves to the result of the blacklist check.
     */
    const emailBlackListed = async (requestObject) => await isBlackListedEmail(requestObject)

    /**
     * Asynchronously checks the subscription status of a given email for a newsletter
     * and handles the response based on the subscription status.
     *
     * This function performs several checks on the provided email:
     * 1. Verifies if the email is already subscribed to the newsletter.
     * 2. Checks if the email is blacklisted.
     * 3. Depending on the user's location (US or not), it either handles a specific
     *    call to Splio or opens a newsletter modal.
     *
     * @param {string} newsletterEmail - The email address to check for newsletter subscription status.
     *
     * @returns {Promise<void>} - A promise that resolves when the subscription status check and
     *                            subsequent actions are complete.
     *
     * @throws {Error} - Throws an error if any of the asynchronous operations fail.
     *
     * @example
     * // Check the subscription status of an email
     * newsletterEmailStatus('example@example.com');
     */
    const newsletterEmailStatus = async (newsletterEmail) => {
        if (!newsletterEmail) {
            return
        }

        const requestObject = {
            email: newsletterEmail,
            siteId: site.id,
            locale: locale.id
        }
        const alreadySubscribed = await emailAlreadySubscribed(requestObject)

        if (alreadySubscribed.success) {
            form.setError('newsletter_footeremail', {
                type: 'already',
                message: alreadySubscribed.message
            })
        } else {
            const blackListedEmail = await emailBlackListed(requestObject)
            if (blackListedEmail.success) {
                form.setError('newsletter_footeremail', {
                    type: 'blacklisted',
                    message: blackListedEmail.message
                })
            } else {
                if (isUS) {
                    await handleSplioCall()
                } else {
                    onNewsletterModalOpen()
                }
            }
        }
    }

    useEffect(() => {
        newsletterEmailStatus(newsletterEmail)
    }, [newsletterEmail])
    /**
     * Handles the subscription process for a newsletter.
     *
     * This asynchronous function manages the subscription process by first checking if the provided
     * email is already subscribed to the newsletter. If the email is already subscribed, it sets an
     * error on the form indicating the subscription status. If not, it further checks if the email
     * is blacklisted. If the email is blacklisted, it sets an appropriate error on the form. If the
     * email passes both checks, it forwards the account details to Splio and displays a success
     * message to the user.
     *
     * @async
     * @function handleSplioCall
     * @returns {Promise<void>} - A promise that resolves when the subscription process is complete.
     */
    const handleSplioCall = async () => {
        if (!newsletterEmail) {
            return
        }
        await forwardAccountToSplio({
            email: newsletterEmail,
            firstName: '',
            lastName: '',
            phoneHome: '',
            marketingConsent: true
        })

        await updateProfileConsentAttributes(newsletterEmail)

        showToast({
            title: formatMessage({
                defaultMessage: 'Subscription successful',
                id: 'newsletter.subscription_successful'
            }),
            status: 'success'
        })
    }

    return (
        <Box as="footer" {...styles.container}>
            {isCheckout ? null : <PreFooter></PreFooter>}
            <Box {...styles.content} bg={colors.lightBeige}>
                <StylesProvider value={styles}>
                    <Box sx={isCheckout ? checkoutFooterStyle : null}>
                        <Stack
                            direction={{base: 'column', lg: 'row'}}
                            spacing={'44px'}
                            pt={'44px'}
                            pb={{base: '44px', lg: '140px'}}
                            px={{base: '10px', lg: '20px'}}
                        >
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'}>
                                    <Heading
                                        as="h2"
                                        fontSize="3xl"
                                        textTransform={'uppercase'}
                                        mb={13}
                                    >
                                        {formatMessage({
                                            id: 'footer.newsletter.title',
                                            defaultMessage: 'Newsletter'
                                        })}
                                    </Heading>
                                    <Text variant="bodyBase2" color={colors.darkGray} maxW={'280'}>
                                        {formatMessage({
                                            id: 'footer.newsletter.info',
                                            defaultMessage:
                                                'Sign in to receive our latest news about collections, products, shows and collaborations'
                                        })}
                                    </Text>
                                    <FieldsetForm
                                        id="newsletter-form"
                                        data-testid="sf-auth-modal-form"
                                        legendText={formatMessage({
                                            defaultMessage: 'Newsletter form',
                                            id: 'newsletter_form.form.legend'
                                        })}
                                        onSubmit={form.handleSubmit(handleNewsletterSubscription)}
                                        error={form.formState.errors?.global?.message}
                                        isMandatoryNote={false}
                                    >
                                        <Box maxW={'343px'} mt={'34px'}>
                                            <Field
                                                {...fields.email}
                                                inputProps={{
                                                    sx: {
                                                        background: colors.lightBeige,
                                                        paddingRight: newsletterButtonWidth
                                                    }
                                                }}
                                            >
                                                <InputRightElement ref={newsletterButtonRef}>
                                                    <IconButton
                                                        type="submit"
                                                        aria-label={formatMessage({
                                                            defaultMessage: 'Submit newsletter',
                                                            id: 'newsletter_form.form.submit'
                                                        })}
                                                        size="inputElement"
                                                        icon={
                                                            <RightArrowIcon
                                                                color="fullBlack"
                                                                boxSize={5}
                                                            />
                                                        }
                                                        onClick={() => form.clearErrors('global')}
                                                        isLoading={isSubmitting}
                                                    />
                                                </InputRightElement>
                                            </Field>
                                        </Box>
                                    </FieldsetForm>
                                    <NewsletterModal
                                        isOpen={isNewsletterModalOpen}
                                        onClose={handleNewsletterModalClose}
                                        submitNewsletter={() => {
                                            handleSplioCall()
                                            onNewsletterModalClose()
                                        }}
                                    ></NewsletterModal>
                                </Box>
                            )}
                            <Box flexBasis={isCheckout ? null : '33%'} alignSelf={'center'}>
                                <Image
                                    align={'center'}
                                    width={{base: '173', lg: '214'}}
                                    height={{base: '170', lg: '210'}}
                                    margin={'auto'}
                                    src={getAssetUrl('static/img/turtle.svg')}
                                    alt={formatMessage({
                                        id: 'footer.turtle',
                                        defaultMessage: 'Turtle'
                                    })}
                                />
                            </Box>
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'} textAlign={{base: 'left', lg: 'right'}}>
                                    {/* TODO: TO REMOVE ONCE REAL STARS RATING IS IMPLEMENTED */}
                                </Box>
                            )}
                        </Stack>
                        <Box px={{base: isCheckout ? '10px' : '0', lg: '20px'}} flexGrow={1}>
                            {isCheckout ? (
                                <PageContext.Provider value={contextValueCheckout}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === true
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box
                                                    key={index}
                                                    flex={'0 1 20%'}
                                                    mb={isDesktop ? '' : '44px'}
                                                >
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : isDesktop ? (
                                <PageContext.Provider value={contextValueDesktop}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box key={index} flex={'0 1 20%'}>
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : (
                                <PageContext.Provider value={contextValueMobile}>
                                    <Accordion allowToggle mb={'44px'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Component
                                                    key={index}
                                                    component={footerLinksList}
                                                />
                                            ))}
                                    </Accordion>
                                </PageContext.Provider>
                            )}
                        </Box>
                    </Box>
                    <FooterBottom />
                </StylesProvider>
            </Box>
        </Box>
    )
}

export default Footer

/**
 * PreFooter Component
 *
 * This component renders the pre-footer section, which includes reinsurance information.
 * It dynamically loads the reinsurance component based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <PreFooter />
 *
 * @returns {JSX.Element} The rendered pre-footer component.
 */

const PreFooter = () => {
    const staleTime = getEnvStaleTime()
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}}, {staleTime: staleTime})
    const footerReinsuranceComponent = footerPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterReinsurance'
    )
    const [contextValueReinsurance, setContextValueReinsurance] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_REINSURANCE
    })
    return (
        <>
            {footerReinsuranceComponent && (
                <PageContext.Provider value={contextValueReinsurance}>
                    <Component component={footerReinsuranceComponent} />
                </PageContext.Provider>
            )}
        </>
    )
}
PreFooter.propTypes = {}
