import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Button
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'
import FieldsetForm from '../shared/ui/FieldsetForm'
import useNewsletterFields from '../forms/useNewsletterFields'
import Field from '../field'

/**
 * NewsletterModal component for displaying a modal with a newsletter subscription form.
 *
 * This component renders a modal dialog that contains a form for subscribing to a newsletter.
 * It includes a consent checkbox and a submit button. The modal can be opened or closed
 * based on the `isOpen` prop, and a callback function is called upon form submission.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOpen - Determines whether the modal is open or closed.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {function} props.submitNewsletter - Callback function to handle newsletter subscription submission.
 * @returns {JSX.Element} The rendered newsletter subscription modal.
 */

export const NewsletterModal = ({isOpen, onClose, submitNewsletter}) => {
    const form = useForm()
    const fields = useNewsletterFields({form, prefix: 'newsletter_modal'})
    const {formatMessage} = useIntl()
    const {
        formState: {errors}
    } = useForm()

    const onSubmit = () => {
        submitNewsletter()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textTransform={'uppercase'}>
                    {formatMessage({
                        id: 'newsletter.modal.title',
                        defaultMessage: 'Thank you'
                    })}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody mt={34}>
                    <FieldsetForm
                        id="newsletter-modal-form"
                        onSubmit={form.handleSubmit(onSubmit)}
                        error={form.formState.errors?.global?.message}
                        legendText={formatMessage({
                            defaultMessage: 'Newsletter consent form',
                            id: 'newsletter_form.consent.form.legend'
                        })}
                        isMandatoryNote={false}
                    >
                        <Field {...fields.consent} />
                        <Button type={'submit'} variant={'primary'} form={'newsletter-modal-form'}>
                            {formatMessage({
                                id: 'newsletter.modal.button',
                                defaultMessage: 'Confirm'
                            })}
                        </Button>
                    </FieldsetForm>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

NewsletterModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    submitNewsletter: PropTypes.func
}
