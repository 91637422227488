export default {
    baseStyle: {
        productViewHeaderContainer: {
            mr: 4,
            spacing: 2,
            align: 'flex-start',
            marginBottom: [4, 4, 4, 0, 0]
        },
        productViewHeaderTitle: {
            width: {base: 'none', md: 'none ', lg: '242.17px'},
            fontFamily: 'Anton',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '16px',
            marginBottom: '10px'
        },
        productViewHeaderPrice: {
            fontFamily: 'Inter',
            fontSize: '12px'
        },
        productViewPageButtonAddToCart: {
            background: 'fullBlack',
            color: 'white'
        },
        productDescription: {
            width: {base: 'none', md: 'none ', lg: '342px'},
            maxWidth: {base: 'none', md: '538px ', lg: '618px'},
            height: {base: 'none', md: 'none', lg: '726px'},
            position: {base: 'none', md: 'none', lg: 'sticky'},
            marginLeft: {base: 'none', md: 'none', lg: 'auto'},
            marginRight: {base: 'none', md: 'none', lg: 'auto'},
            marginTop: {base: 'none', md: 'none', lg: '120px'},
            top: {base: 'none', md: 'none ', lg: '80px'}
        },
        productImages: {
            position: {base: '', md: '', lg: 'sticky'},
            minWidth: {base: 'none', lg: '50%'},
            maxWidth: {base: 'none', lg: '816px', xl: '816px'},
            margin: {base: '-16px -16px 0 -16px', md: '0 0 32px 0', lg: '0'}
        },
        productBrandHeader: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: {
                base: '20px',
                md: '32px'
            },
            fontStyle: 'normal',
            lineHeight: {
                base: '28px',
                md: '140%'
            }
        },
        addToWishListInTitle: {
            display: {base: 'none', md: 'none', lg: 'inline-flex'},
            width: '12px',
            height: '12px'
        }
    }
}
