import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

/**
 * Constructs a URL for a specific page based on the given page ID.
 *
 * @param {string|number} pageID - The unique identifier for the page.
 * @returns {string} - The constructed URL string for the page.
 */
export const pageUrlBuilder = (pageID) => `/page/${pageID}`

/**
 * Retrieves the host URL for the SFCC (Salesforce Commerce Cloud) proxy configuration.
 *
 * This function accesses the server-side rendering (SSR) parameters from the configuration
 * and searches for the proxy configuration with the path 'sfcc'. If found, it returns the
 * host associated with this proxy configuration. If no such configuration is found, it returns null.
 *
 * @returns {string|null} The host URL for the SFCC proxy configuration, or null if not found.
 */
function getSFCCHost() {
    const {ssrParameters} = getConfig()
    const proxyConfigs = ssrParameters.proxyConfigs
    const sfccConfig = proxyConfigs.find((proxy) => proxy.path === 'sfcc')
    return sfccConfig ? sfccConfig.host : null
}

/**
 * Determines whether the preparation page URL should be used.
 * This function evaluates certain conditions to decide if the URL
 * for the preparation page is applicable in the current context.
 *
 * @returns {boolean} - Returns true if the preparation page URL should be used, otherwise false.
 */
export const getPreparationPageURL = (siteId) => {
    const host = getSFCCHost()
    return `https://${host}/on/demandware.store/Sites-${siteId}-Site/default/OrderPreparation-Entrance`
}
