'use strict'
// React Hooks
import {useEffect} from 'react'

// PWA OOTB Hooks
import {useShopperBasketsMutation} from '@salesforce/commerce-sdk-react'
import {useCurrentBasket} from '@salesforce/retail-react-app/app/hooks/use-current-basket'

/**
 * Custom hook to manage gift card currency operations within a shopping basket.
 * This hook automatically removes gift certificate items from the basket if the basket's currency changes.
 *
 * @returns {void}
 *
 * @description
 * Utilizes the `useCurrentBasket` hook to access the current basket data and the
 * `useShopperBasketsMutation` hook to perform mutations on the basket.
 *
 * The hook listens for changes in the basket's currency and iterates over the gift certificate items.
 * If a gift certificate item's currency does not match the basket's currency, it triggers a mutation
 * to remove the gift certificate item from the basket.
 *
 * @example
 * // Usage in a component
 * const Component = () => {
 *     useGiftCardCurrencyOperations();
 *     return <div>Your component logic</div>;
 * }
 */
export const useGiftCardCurrencyOperations = () => {
    const {data: basket} = useCurrentBasket()

    const removeGiftCertificateMutation = useShopperBasketsMutation(
        'removeGiftCertificateItemFromBasket'
    )

    useEffect(() => {
        // Remove gift certificate items from basket if currency of basket has been changed
        if (basket && basket.basketId && basket.currency) {
            const gcItems = basket.giftCertificateItems
            if (gcItems && gcItems.length > 0) {
                gcItems.forEach((gcItem) => {
                    if (basket.currency !== gcItem.c_giftCertificateCurrencyCode) {
                        removeGiftCertificateMutation.mutate(
                            {
                                parameters: {
                                    basketId: basket.basketId,
                                    giftCertificateItemId: gcItem.giftCertificateItemId
                                }
                            },
                            {
                                onSuccess: (res) => {
                                    // @TODO [FD]: Handle translations and pop-ups here
                                },
                                onError: (res) => {
                                    // @TODO [FD]: Handle translations and pop-ups here
                                }
                            }
                        )
                    }
                })
            }
        }
    }, [basket?.currency])
}
