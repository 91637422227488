'use strict'

import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import EmailMgr from './../custom-api/email-custom-api'
import {useAccessToken} from '@salesforce/commerce-sdk-react'

/**
 * Custom hook for handling GDPR-related functionalities, specifically for sending
 * "Do Not Sell" emails to US customers. This hook utilizes access tokens and email
 * management configurations to facilitate the email sending process.
 *
 * @returns {Object} An object containing the following method:
 *
 * @method
 * @name sendDoNotSellUSEmail
 * @async
 * @param {Object} mailObj - The email object containing the necessary details
 *                           for sending the "Do Not Sell" email.
 * @returns {Promise<Object>} A promise that resolves to an object with the following properties:
 *                            - {boolean} success: Indicates whether the email was sent successfully.
 *                            - {string} message: Provides additional information about the result
 *                                                 or error encountered during the email sending process.
 */
export const useGDPRPolicy = () => {
    const {getTokenWhenReady} = useAccessToken()
    const {app: appConfig} = getConfig()
    const {site, locale} = useMultiSite()
    const emailApi = new EmailMgr({
        parameters: {
            siteId: site.id,
            organizationId: appConfig.commerceAPI.parameters.organizationId,
            locale: locale.id
        }
    })

    return {
        /**
         * Sends an email regarding the privacy policy page using the provided mail object.
         *
         * This function attempts to send an email through the email API. It first retrieves
         * an access token required for authentication, then uses this token to send the email.
         * If the email is successfully sent, the result object will reflect this success.
         * In case of an error during the process, the result object will contain the error message.
         *
         * @async
         * @param {Object} mailObj - The mail object containing email details such as recipient, subject, and body.
         * @returns {Promise<Object>} A promise that resolves to an object containing the success status and a message.
         * @property {boolean} success - Indicates whether the email was sent successfully.
         * @property {string} message - Contains a success message or an error message if the email sending failed.
         */
        async sendPrivacyPageEmail(mailObj) {
            let result = {
                success: false,
                message: ''
            }

            try {
                const token = await getTokenWhenReady()
                const emailResult = await emailApi.sendEmail(mailObj, token)

                result.success = emailResult.success
                result.message = emailResult.message
            } catch (error) {
                result.message = error.message
                result.success = false
            }

            return result
        },

        /**
         * Sends a "Do Not Sell" email for US customers using the provided mail object.
         *
         * This function attempts to send an email through the email API, using an access token
         * obtained from the `getTokenWhenReady` function. It returns an object indicating the 
         * success or failure of the email sending operation, along with a message detailing the result.
         *
         * @async
         * @param {Object} mailObj - The mail object containing email details such as recipient, subject, and body.
         * @returns {Promise<Object>} A promise that resolves to an object with the following properties:
         * - {boolean} success - Indicates whether the email was sent successfully.
         * - {string} message - A message describing the result of the email sending operation.
         */
        async sendDoNotSellUSEmail(mailObj) {
            let result = {
                success: false,
                message: ''
            }

            try {
                const accessToken = await getTokenWhenReady()
                const emailResult = await emailApi.sendEmail(mailObj, accessToken)

                result.success = emailResult.success
                result.message = emailResult.message
            } catch (error) {
                result.message = error.message
                result.success = false
            }

            return result
        }
    }
}
