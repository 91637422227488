'use strict'

import {HTTPError} from '@salesforce/pwa-kit-react-sdk/ssr/universal/errors'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {helpers} from 'commerce-sdk-isomorphic'

/**
 * Converts a string to camelCase
 * @param {string} str - The string to be converted
 * @returns {string} - The converted string in camelCase
 */
const toCamel = (str) => {
    if (str.startsWith('_') || str.startsWith('c_')) {
        return str
    }
    return str.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '')
    })
}

/**
 * Checks if the given value is an object
 * @param {any} obj - the value to check
 * @returns {boolean} - true if the given value is an object, false otherwise
 */
const isObject = (obj) => {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

/**
 * Converts all object keys to camelCase
 * @param {Object} obj - The object to convert
 * @returns {Object} - The object with all keys converted to camelCase
 */
export const keysToCamel = (obj) => {
    if (isObject(obj)) {
        const n = {}

        Object.keys(obj).forEach((k) => {
            n[toCamel(k)] = keysToCamel(obj[k])
        })

        return n
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i)
        })
    }

    return obj
}

/**
 * Creates a function to fetch data from the SCAPI (Salesforce Commerce API) with specified configurations.
 *
 * @param {Object} commerceAPIConfig - The configuration object for the commerce API.
 * @param {string} commerceAPIConfig.parameters.clientId - The client ID for the commerce API.
 * @param {string} commerceAPIConfig.parameters.organizationId - The organization ID for the commerce API.
 * @param {string} commerceAPIConfig.parameters.shortCode - The short code for the commerce API.
 * @param {string} commerceAPIConfig.parameters.siteId - The site ID for the commerce API.
 * @param {string} commerceAPIConfig.parameters.locale - The locale for the commerce API.
 * @returns {Function} A function that fetches data from the SCAPI.
 *
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {string} method - The HTTP method to use for the request (e.g., 'GET', 'POST').
 * @param {Array} args - The arguments to pass to the fetch function.
 * @param {string} methodName - The name of the method being called.
 * @param {Object} params - Additional parameters for the request.
 * @param {boolean} params.isCached - Whether the request should use the caching proxy.
 * @param {string} params.apiPath - The API path for the custom endpoint.
 * @param {string} params.accessToken - The access token for authorization.
 * @param {string} [params.siteID] - The site ID to override the default site ID.
 * @param {string} [params.localeID] - The locale ID to override the default locale.
 * @param {Object} [params.body] - The body of the request for POST methods.
 * @param {string} [apiVersion='v1'] - The version of the API to use (defaults to 'v1').
 *
 * @returns {Promise<Object>} The response from the SCAPI.
 *
 * @throws {HTTPError} Throws an HTTPError if the request fails.
 */
export const createScapiFetch =
    (commerceAPIConfig) => async (endpoint, method, args, methodName, params, apiVersion) => {
        const {app: appConfig} = getConfig()

        const proxy = params.isCached ? `/mobify/caching/api` : `/mobify/proxy/api`
        const host = `${getAppOrigin()}${proxy}`

        const clientConfig = {
            proxy: host,
            parameters: {
                clientId: appConfig.commerceAPI.parameters.clientId,
                organizationId: appConfig.commerceAPI.parameters.organizationId,
                shortCode: appConfig.commerceAPI.parameters.shortCode,
                siteId: appConfig.commerceAPI.parameters.siteId
            }
        }

        const version = apiVersion || 'v1' // default version has been added to maintain v1 hooks
        // Required params: apiName, endpointPath, shortCode, organizaitonId
        // Required path params can be passed into:
        // options.customApiPathParameters or clientConfig.parameters
        const customApiArgs = {
            apiName: params.apiPath,
            apiVersion: version, // defaults to v1 if not provided
            endpointPath: endpoint
        }

        const headers = {
            ...args[0].headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${params.accessToken}`,
        }

        args[0].querystring.siteId = params.siteID
            ? params.siteID
            : commerceAPIConfig.parameters.siteId
        args[0].querystring.locale = params.localeID
            ? params.localeID
            : commerceAPIConfig.parameters.locale

        const queryParameters = args[0].querystring

        let response

        try {
            let options = {
                method: method,
                parameters: queryParameters,
                headers: headers,
                customApiPathParameters: customApiArgs
            }

            if (params.body) {
                options.body = JSON.stringify(params.body)
            }

            response = await helpers.callCustomEndpoint({
                options: options,
                clientConfig: clientConfig,
                // Flag to retrieve raw response or data from helper function
                rawResponse: false
            })
        } catch (error) {
            throw new HTTPError(500, error.message)
        }

        return response
    }

/**
 * Builds a query string from an object of parameters
 * @param {Object} params - an object of parameters
 * @returns {string} - a query string
 */
export function buildQueryString(params) {
    let queryString = Object.keys(params)
        .map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
    return queryString
}

/**
 * Converts an error to a format that is compliant with OCAPI
 * @param {Object} error - the error object to be converted
 * @returns {Object} - an object with the converted error
 */
export const convertError = (error) => {
    return {
        title: error.message,
        type: error.type,
        detail: error.message,
        // Unique to OCAPI I think
        arguments: error.arguments,
        fault: true
    }
}
