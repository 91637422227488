import React from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'

import {Box, Text, Button} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'
import {COOKIE_POLICY_URL} from '../../constants'

/**
 * CookieConsentBanner is a React component that displays a banner
 * for handling user consent regarding cookies.
 *
 * This component is typically used to inform users about cookie usage
 * and to obtain their consent. It takes a handler function as a prop
 * to manage the user's consent response.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleCookieConsent - A function to handle the user's cookie consent action.
 * @returns {JSX.Element} The rendered CookieConsentBanner component.
 */

const CookieConsentBanner = ({handleCookieConsent}) => {
    const intl = useIntl()
    return (
        <Box
            pos={'fixed'}
            height={'100vh'}
            width={'100vw'}
            backgroundColor={'rgba(0,0,0,0.2)'}
            zIndex={'modal'}
            top={0}
            left={0}
            p={'10px'}
        >
            <Box
                backgroundColor={'white'}
                padding={'30px 20px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                <Link
                    as={Button}
                    alignSelf={'flex-end'}
                    textTransform={'uppercase'}
                    p={0}
                    height={'fit-content'}
                    mb={'40px'}
                    onClick={() => handleCookieConsent(false)}
                >
                    {intl.formatMessage({
                        id: 'cookie_banner_consent.decline',
                        defaultMessage: 'Continue without accepting'
                    })}
                </Link>
                <Text variant={'bodyBase2'} mb={'40px'} textAlign={'center'}>
                    {intl.formatMessage({
                        id: 'cookie_banner_consent.text',
                        defaultMessage: `To provide the best possible shopping experience and personalized advertising and content, 
                        Vilebrequin uses cookies to store information. By clicking ‘Accept All’, you are accepting our`
                    })}{' '}
                    <Link href={COOKIE_POLICY_URL}>
                        {intl.formatMessage({
                            id: 'cookie_banner_consent.link',
                            defaultMessage: 'Cookie Policy.'
                        })}
                    </Link>
                </Text>
                <Box>
                    <Button
                        onClick={() => handleCookieConsent(true)}
                        variant={'primary'}
                        mr={'10px'}
                    >
                        {intl.formatMessage({
                            id: 'cookie_banner_consent.consent',
                            defaultMessage: 'Accept all'
                        })}
                    </Button>
                    <Button variant={'secondary'} as={Link} href={COOKIE_POLICY_URL}>
                        {intl.formatMessage({
                            id: 'cookie_banner_consent.settings',
                            defaultMessage: 'Settings'
                        })}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

CookieConsentBanner.propTypes = {
    handleCookieConsent: PropTypes.func.isRequired
}

export default CookieConsentBanner
