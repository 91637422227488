import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    List,
    ListItem,
    Heading,
    HStack,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'

/**
 * A component that renders a list of links with an optional heading.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.links - An array of link objects to be displayed.
 * @param {Object} props.links[].href - The URL the link points to.
 * @param {Object} props.links[].text - The text to display for the link.
 * @param {string|Object} [props.heading=''] - The heading text or object for the list.
 * @param {string} [props.variant] - The layout variant of the list, either 'vertical' or 'horizontal'.
 * @param {string} [props.color] - The color of the list container.
 * @param {Function} [props.onLinkClick] - Callback function to handle link click events.
 * @param {Object} [props.headingLinkRef] - Ref object for the heading link.
 * @param {Object} [props.otherProps] - Additional props to be passed to the container.
 * @returns {JSX.Element} The rendered LinksList component.
 */

const LinksList = ({
    links = [],
    heading = '',
    variant,
    color,
    onLinkClick,
    headingLinkRef,
    ...otherProps
}) => {
    const styles = useMultiStyleConfig('LinksList', {variant})
    return (
        <Box {...styles.container} {...(color ? {color: color} : {})} {...otherProps}>
            {heading &&
                (heading.href ? (
                    <Link
                        to={heading.href}
                        onClick={onLinkClick}
                        ref={headingLinkRef}
                        {...styles.headingLink}
                    >
                        <Heading {...styles.heading} {...(heading.styles ? heading.styles : {})}>
                            {heading.text}
                        </Heading>
                    </Link>
                ) : (
                    <Heading {...styles.heading}>{heading}</Heading>
                ))}

            {links && (
                <List role={undefined} spacing={5} {...styles.list}>
                    {variant === 'horizontal' ? (
                        <HStack>
                            {links.map(
                                (link, i) =>
                                    link.href && (
                                        <ListItem
                                            key={i}
                                            {...styles.listItem}
                                            sx={styles.listItemSx}
                                        >
                                            <Link
                                                to={link.href}
                                                onClick={onLinkClick}
                                                {...(link.styles ? link.styles : {})}
                                            >
                                                {link.text}
                                            </Link>
                                        </ListItem>
                                    )
                            )}
                        </HStack>
                    ) : (
                        links.map(
                            (link, i) =>
                                link.href && (
                                    <ListItem key={i}>
                                        <Link
                                            to={link.href}
                                            onClick={onLinkClick}
                                            {...(link.styles ? link.styles : {})}
                                        >
                                            {link.text}
                                        </Link>
                                    </ListItem>
                                )
                        )
                    )}
                </List>
            )}
        </Box>
    )
}

LinksList.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string
        })
    ).isRequired,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    variant: PropTypes.oneOf(['vertical', 'horizontal']),
    color: PropTypes.string,
    onLinkClick: PropTypes.func,
    headingLinkRef: PropTypes.object
}

export default LinksList
