import React, {useEffect, useRef, useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useSearchSuggestions} from '@salesforce/commerce-sdk-react'
import {useIntl} from 'react-intl'
import {
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Box,
    Flex,
    HStack,
    Stack,
    VisuallyHidden,
    Text,
    Button
} from '@salesforce/retail-react-app/app/components/shared/ui'
import SearchSuggestions from './partials/search-suggestions'
import {CloseIcon} from '../custom-icons'
import LoadingSpinner from '../loading-spinner'

import {
    productUrlBuilder,
    searchUrlBuilder,
    categoryUrlBuilder
} from '@salesforce/retail-react-app/app/utils/url'
import useNavigation from '@salesforce/retail-react-app/app/hooks/use-navigation'
import {pageUrlBuilder} from '../../utils/url-utils'
import {RECENT_SEARCH_MIN_LENGTH} from '@salesforce/retail-react-app/app/constants'
import {togglePageScroll} from '../../utils/utils'

/**
 * Processes search suggestions and categorizes them into types.
 *
 * @param {Object} searchSuggestions - The search suggestions object.
 * @param {Object[]} [searchSuggestions.categorySuggestions.categories] - Array of category suggestions.
 * @param {string} searchSuggestions.categorySuggestions.categories[].id - The ID of the category.
 * @param {string} searchSuggestions.categorySuggestions.categories[].name - The name of the category.
 * @param {Object[]} [searchSuggestions.productSuggestions.products] - Array of product suggestions.
 * @param {string} searchSuggestions.productSuggestions.products[].productId - The ID of the product.
 * @param {string} searchSuggestions.productSuggestions.products[].productName - The name of the product.
 * @param {Object[]} [searchSuggestions.contentSuggestions.c_pdSuggestions] - Array of content page suggestions.
 * @param {string} searchSuggestions.contentSuggestions.c_pdSuggestions[].id - The ID of the content page.
 * @param {string} searchSuggestions.contentSuggestions.c_pdSuggestions[].name - The name of the content page.
 *
 * @returns {Object} An object containing categorized suggestions.
 * @returns {Object[]} return.categories - Array of formatted category suggestions.
 * @returns {Object[]} return.products - Array of formatted product suggestions.
 * @returns {Object[]} return.pages - Array of formatted content page suggestions.
 */
const handleSearch = (searchSuggestions) => {
    let suggestionTypes = {
        categories: searchSuggestions?.categorySuggestions?.categories?.map((suggestion) => {
            return {
                type: 'category',
                id: suggestion.id,
                link: categoryUrlBuilder({id: suggestion.id}),
                name: suggestion.name
            }
        }),
        products: searchSuggestions?.productSuggestions?.products?.map((product) => {
            return {
                type: 'product',
                productId: product.productId,
                name: product.productName,
                link: productUrlBuilder({id: product.productId})
            }
        }),
        pages: searchSuggestions?.contentSuggestions?.c_pdSuggestions?.map((page) => {
            return {
                type: 'page',
                id: page.id,
                name: page.name,
                link: pageUrlBuilder(page.id)
            }
        })
    }

    return suggestionTypes
}

/**
 * Search component that provides a search input with suggestions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.onClose - Function to call when the close button is clicked.
 *
 * @returns {JSX.Element} The rendered component.
 */

const Search = ({onClose, ...props}) => {
    const navigate = useNavigation()
    const intl = useIntl()
    const [distance, setDistance] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
    const [suggestionsLength, setSuggestionsLength] = useState(false)

    const searchInputRef = useRef()

    /**
     * Focuses the search input element if it is currently available.
     *
     * This function checks if the `searchInputRef` is defined and points to a valid DOM element,
     * then calls the `focus` method on that element to bring it into focus.
     */
    const focusInputRef = () => {
        searchInputRef.current?.focus()
    }

    useEffect(() => {
        focusInputRef()
    }, [])

    const searchSuggestion = useSearchSuggestions(
        {
            parameters: {
                q: searchQuery
            }
        },
        {
            enabled: searchQuery?.length >= RECENT_SEARCH_MIN_LENGTH
        }
    )
    const searchSuggestions = useMemo(
        () => handleSearch(searchSuggestion.data, searchInputRef?.current?.value),
        [searchSuggestion]
    )

    useEffect(() => {
        const calculateDistance = () => {
            if (searchInputRef.current) {
                const rect = searchInputRef.current.getBoundingClientRect()
                const distanceFromBottom = window.innerHeight - rect.bottom
                setDistance(distanceFromBottom)
            }
        }
        calculateDistance()
        const handleResizeScrollClick = () => {
            calculateDistance()
        }
        window.addEventListener('resize', handleResizeScrollClick)
        window.addEventListener('scroll', handleResizeScrollClick)
        window.addEventListener('click', handleResizeScrollClick)
        return () => {
            window.removeEventListener('resize', handleResizeScrollClick)
            window.removeEventListener('scroll', handleResizeScrollClick)
            window.removeEventListener('click', handleResizeScrollClick)
        }
    }, [])

    useEffect(() => {
        setSuggestionsLength(
            (searchSuggestions.products?.length || 0) +
                (searchSuggestions.categories?.length || 0) +
                (searchSuggestions.pages?.length || 0)
        )
    }, [searchSuggestions])

    useEffect(() => {
        togglePageScroll(!isSuggestionsOpen)
    }, [isSuggestionsOpen])

    const onSearchInputChange = (e) => {
        let inputValue = e.target.value
        if (inputValue.length >= RECENT_SEARCH_MIN_LENGTH) {
            setIsSuggestionsOpen(true)
            setSearchQuery(inputValue)
        } else {
            setIsSuggestionsOpen(false)
            setSearchQuery('')
        }
    }

    const onSubmitSearch = (e) => {
        e.preventDefault()

        let searchText = searchInputRef.current.value.trim()

        if (searchText.length < 1) {
            return
        }
        onClose()
        navigate(searchUrlBuilder(searchText))
    }

    return (
        <Box
            position={'relative'}
            role="region"
            aria-label={intl.formatMessage({
                defaultMessage: 'Search',
                id: 'search.region.label'
            })}
        >
            <form onSubmit={onSubmitSearch}>
                <HStack>
                    <InputGroup>
                        <Input
                            autoComplete="off"
                            id="search-input"
                            onChange={onSearchInputChange}
                            type="search"
                            borderWidth="0"
                            borderBottomWidth="1px"
                            borderTopWidth="1px"
                            fontSize={'12px'}
                            sx={{
                                '&::-webkit-search-cancel-button': {display: 'none'},
                                '&::placeholder': {fontSize: '11px'}
                            }}
                            ref={searchInputRef}
                            {...props}
                            variant="basic"
                            defaultValue={''}
                            aria-labelledby="searchInputDescription"
                            onKeyDown={(e) => {
                                if (e.key === 'Tab' && e.shiftKey) {
                                    e.preventDefault()
                                    onClose()
                                }
                            }}
                        />
                        <VisuallyHidden id="searchInputDescription">
                            {intl.formatMessage({
                                defaultMessage:
                                    'The following text field filters the results that follow as you type.',
                                id: 'search.input.description'
                            })}
                        </VisuallyHidden>
                        <InputRightElement>
                            <IconButton
                                icon={<CloseIcon boxSize="12px" />}
                                size="sm"
                                onClick={onClose}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Cancel',
                                    id: 'search.action.cancel'
                                })}
                            />
                        </InputRightElement>
                        <VisuallyHidden>
                            <Button
                                type="submit"
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Submit search',
                                    id: 'search.input.submit'
                                })}
                            />
                        </VisuallyHidden>
                    </InputGroup>
                </HStack>
            </form>
            <Stack
                position={'absolute'}
                left={0}
                right={0}
                top={`100%`}
                zIndex={9999}
                width={'100%'}
                background={'rgba(0,0,0,0.2)'}
                display={isSuggestionsOpen ? 'flex' : 'none'}
                direction={'row'}
                height={`${distance}px`}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                overflow={'hidden'}
                gap={0}
                onClick={onClose}
            >
                <Flex
                    background="white"
                    left={0}
                    right={0}
                    width={{base: '100%', lg: '50%'}}
                    height={{base: `${distance}px`, lg: 'auto'}}
                    overflowY={'scroll'}
                    p={'20px'}
                    position={'absolute'}
                    onClick={(e) => e.stopPropagation()}
                >
                    {searchSuggestion.isLoading ? (
                        <Box maxHeight={'200px'} height={`${distance}px`}>
                            <LoadingSpinner wrapperStyles={{background: 'white'}} />
                        </Box>
                    ) : suggestionsLength ? (
                        <>
                            <VisuallyHidden aria-live="assertive">
                                {`${suggestionsLength} ${intl.formatMessage({
                                    defaultMessage: 'suggestions available',
                                    id: 'search.available_suggestions'
                                })}`}
                            </VisuallyHidden>
                            <SearchSuggestions
                                suggestions={searchSuggestions}
                                redirect={() => {
                                    onClose()
                                }}
                                focusInputRef={focusInputRef}
                            />
                        </>
                    ) : (
                        <Text variant={'bodySmall'}>
                            {intl.formatMessage({
                                defaultMessage: 'No suggestions',
                                id: 'search.empty.message'
                            })}
                        </Text>
                    )}
                </Flex>
            </Stack>
        </Box>
    )
}
Search.propTypes = {
    onClose: PropTypes.func
}
Search.displayName = 'SearchInput'

export default Search
