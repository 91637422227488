import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import Suggestion from './suggestion'

/**
 * A component that renders search suggestions for products, categories, and pages.
 * It manages focus and keyboard navigation within the suggestion list.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.suggestions - An object containing arrays of suggestions for products, categories, and pages.
 * @param {Array} props.suggestions.products - An array of product suggestions.
 * @param {Array} props.suggestions.categories - An array of category suggestions.
 * @param {Array} props.suggestions.pages - An array of page suggestions.
 * @param {Function} props.redirect - A function to handle redirection when a suggestion is clicked.
 * @param {Function} props.focusInputRef - A function to focus the input field when navigation reaches the end of the suggestions.
 * @returns {JSX.Element} The rendered component.
 */
const SearchSuggestions = ({suggestions, redirect, focusInputRef}) => {
    const {products, categories, pages} = suggestions
    const intl = useIntl()
    let linkRefs = []

    /**
     * Adds a new element reference to the linkRefs array.
     *
     * This function is used to collect references to link elements, which can be used
     * for managing focus and keyboard navigation within the component.
     *
     * @param {HTMLElement} el - The DOM element to be added to the linkRefs array.
     */
    const updateLinkRefs = (el) => {
        linkRefs.push(el)
    }

    useEffect(() => {
        const lastLinkRef = linkRefs[linkRefs.length - 1]
        const handleKeyDown = (e) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault()
                focusInputRef()
            }
        }
        if (lastLinkRef) lastLinkRef.addEventListener('keydown', handleKeyDown)

        return () => {
            if (lastLinkRef) lastLinkRef.removeEventListener('keydown', handleKeyDown)
        }
    }, [linkRefs, focusInputRef])
    const suggestionTypes = [
        {
            title: intl.formatMessage({
                id: 'search.suggestion.products.title',
                defaultMessage: 'Suggestions'
            }),
            suggestion: products
        },
        {
            title: intl.formatMessage({
                id: 'search.suggestion.categories.title',
                defaultMessage: 'Categories'
            }),
            suggestion: categories
        },
        {
            title: intl.formatMessage({
                id: 'search.suggestion.pages.title',
                defaultMessage: 'Pages'
            }),
            suggestion: pages
        }
    ]

    return (
        <Stack
            spacing={0}
            left={0}
            bottom={0}
            width={'100%'}
            direction={{base: 'column', lg: 'row'}}
            flexWrap={'wrap'}
            justify={{base: 'unset', lg: 'flex-end'}}
        >
            {suggestionTypes.map(
                ({title, suggestion}, index) =>
                    suggestion?.length > 0 && (
                        <Suggestion
                            key={index}
                            title={title}
                            suggestion={suggestion}
                            redirect={redirect}
                            updateLinkRefs={updateLinkRefs}
                        />
                    )
            )}
        </Stack>
    )
}

SearchSuggestions.propTypes = {
    suggestions: PropTypes.object,
    redirect: PropTypes.func,
    focusInputRef: PropTypes.func
}

export default SearchSuggestions
