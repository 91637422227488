import React, {useEffect, useState} from 'react'

/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {
    Flex,
    Text,
    List,
    ListItem,
    Box,
    Link,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PDCTitle from '../../components/pdc-title/index.jsx'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../hooks/use-pdc-responsive-element.js'
import {usePage} from '@salesforce/commerce-sdk-react'
import {
    DYNAMIC_IMAGE_WIDTHS,
    DISPATCH_COUNTRIES,
    HOME_HREF,
    TURKEY_PARTNER_SITE,
    RUSSIA_PARTNER_SITE
} from '../../constants.js'

import {BrandLogo, BrandLogoWhite} from '../../components/custom-icons'
import {setLocalStorageJSONItem, getEnvStaleTime} from '../../utils/utils'

const continents = DISPATCH_COUNTRIES

export const CountryDispatch = () => {
    const staleTime = getEnvStaleTime()
    const {data} = usePage(
        {parameters: {pageId: 'deliverycountrydispatchpage'}},
        {staleTime: staleTime}
    )
    const component = data?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcCountryDispatch'
    )
    const componentData = component?.data

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: componentData?.smallImage,
            mediumEl: componentData?.mediumImage,
            largeEl: componentData?.largeImage,
            xlargeEl: componentData?.xlargeImage,
            xxlargeEl: componentData?.xxlargeImage
        })
    )

    const [preferredLocale, setPreferredLocale] = useState()

    useEffect(() => {
        setPreferredLocale(navigator.language.split('-')[0])
    }, [])

    /**
     * Handles the selection of a country from a dropdown or similar UI element.
     *
     * This asynchronous function is triggered when a user selects a country. It retrieves
     * the country code from the event target, determines the shipping details for the selected
     * country, and updates the shopper's context with the new country code and currency.
     * It also navigates the user to the men's category page.
     *
     * @async
     * @function onCountrySelect
     * @param {Object} element - The event object from the country selection UI element.
     * @param {Object} element.target - The target element of the event.
     * @param {string} element.target.id - The ID of the target element, representing the country code.
     *
     * @returns {Promise<void>} - A promise that resolves when the function completes its operations.
     */
    const onCountrySelect = async (e) => {
        e.preventDefault()
        const countryCode = e.target.id
        const url = e.target.href

        setLocalStorageJSONItem('countryCode', countryCode)
        window.location.href = url
    }

    return (
        <Box
            as="section"
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
        >
            <Box width={['100%', null, '50%']} position="relative">
                <DynamicImage
                    src={`${currentImage?.url}[?sw={width}]`}
                    widths={DYNAMIC_IMAGE_WIDTHS}
                    position="relative"
                    imageProps={{
                        width: '100%',
                        loading: 'auto',
                        fetchpriority: 'high',
                        alt:
                            componentData?.imageAlt === 'empty'
                                ? ''
                                : componentData?.imageAlt || componentData?.title,
                        title:
                            componentData?.imageTitle === 'empty'
                                ? ''
                                : componentData?.imageTitle || componentData?.title
                    }}
                />
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    direction={'column'}
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    p={'34px'}
                >
                    {componentData?.isLogoTransparent ? (
                        <BrandLogoWhite width="94px" height="auto" />
                    ) : (
                        <BrandLogo width="94px" height="auto" />
                    )}
                    <PDCTitle
                        title={componentData?.title}
                        titleFontColor={componentData?.titleFontColor || 'white'}
                        titleHeadingLevel={componentData?.titleHeadingLevel}
                        txtHorizontalPos={'center'}
                        size="4xl"
                        mt="50px"
                    />
                </Flex>
            </Box>
            <Box
                width={['100%', null, '50%']}
                p={['31px 18px', null, '41px 38px', '76px 0 51px 71px']}
                position="relative"
            >
                <Text
                    dangerouslySetInnerHTML={{
                        __html: componentData?.subTitle
                    }}
                    variant="eyeBrowLarge"
                    color={'fullBlack'}
                    mb={'25px'}
                />
                <List sx={{columns: {base: '2', lg: '3'}}}>
                    {continents.map((continent) => (
                        <ListItem key={continent.continent} mb="12px">
                            <Text
                                color={'fullBlack'}
                                mb={'2px'}
                                fontFamily={'heading'}
                                fontSize={'3xl'}
                            >
                                {continent.continent}
                            </Text>

                            <List>
                                {continent.countries.map((country) => {
                                    const url =
                                        country.code === 'TR'
                                            ? TURKEY_PARTNER_SITE
                                            : country.code === 'RU'
                                            ? RUSSIA_PARTNER_SITE
                                            : `/${
                                                  country.code === 'US' ? 'us' : 'eu'
                                              }/${preferredLocale}${HOME_HREF}`
                                    return (
                                        <ListItem key={country.code} display={'flex'}>
                                            <Link
                                                onClick={onCountrySelect}
                                                id={country.code}
                                                textDecoration={'none'}
                                                display={'inline-block'}
                                                href={url}
                                            >
                                                <Text
                                                    as="span"
                                                    variant="bodyBase2"
                                                    color="darkGray"
                                                    pointerEvents={'none'}
                                                >
                                                    {country.name}
                                                </Text>
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    )
}

CountryDispatch.css = css`
    .chakra-link {
        padding: 18px 20px;
        text-decoration: none;
    }
`

CountryDispatch.propTypes = {
    title: PropTypes.string,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    isLogoTransparent: PropTypes.bool,
    smallImage: PropTypes.object,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
