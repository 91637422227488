import React from 'react'
import {Redirect} from 'react-router'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import {getPreparationPageURL} from '../../utils/url-utils'

/**
 * The `PreparationPage` component is responsible for redirecting users to the appropriate
 * preparation page URL based on the current site context. It utilizes the `useMultiSite`
 * hook to determine the active site and its associated ID.
 *
 * @component
 * @returns {JSX.Element} A JSX element that performs a redirect to the preparation page URL.
 *
 * @example
 * // Usage in a React component
 * <PreparationPage />
 *
 * @remarks
 * This component is a functional component that leverages React hooks to access the
 * multi-site context. It uses the `Redirect` component to navigate users to the correct
 * preparation page based on the site ID.
 *
 * @see {@link useMultiSite} for more information on how the site context is retrieved.
 * @see {@link getPreparationPageURL} for details on how the preparation page URL is constructed.
 */
export const PreparationPage = () => {
    const {site} = useMultiSite()
    return <Redirect to={getPreparationPageURL(site?.id)} />
}
